import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Ethereum`}</h1>
    <p>{`Ethereum is a blockchain designed to execute smart contracts and run decentralized applications.`}</p>
    <p>{`The platform can be described as an open-source, public, permissionless, distributed ledger.`}</p>
    <Box sx={{
      padding: 4
    }} mdxType="Box">
      <h2>{`Smart Contracts`}</h2>
      <p>{`Smart contracts enable developers to build applications with business logic that execute in a trustless environment, while leveraging the high availability of the Ethereum network.`}</p>
      <List mdxType="List">
        <p><a parentName="p" {...{
            "href": "https://docs.ethhub.io/ethereum-basics/what-is-ether/"
          }}>{`What is Ether? (ETH)`}</a></p>
        <p><a parentName="p" {...{
            "href": "https://docs.ethhub.io/using-ethereum/how-to-buy-ether/"
          }}>{`How to Buy Ether (ETH)`}</a></p>
        <p><a parentName="p" {...{
            "href": "https://docs.ethhub.io/built-on-ethereum/built-on-ethereum/"
          }}>{`Built on Ethereum `}</a></p>
      </List>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      